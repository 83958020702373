import { FUNDING, PayPalButtons, PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import React, { useRef } from 'react';

interface PayPalModalProps {
    visible: boolean;
    onHide: () => void;
    amount: string;
    currency: string;
    onSuccess: (details: any) => void;
    onError: (err: any) => void;
    subName: string;
    userId: string | null;
}

const PayPalModal: React.FC<PayPalModalProps> = ({ visible, onHide, amount, currency, onSuccess, onError, subName, userId }) => {
    const msgs = useRef<Messages>(null);

    const scriptOptions: ReactPayPalScriptOptions = {
        clientId: "AX9cEGu-j89crhEFrBcxfk7u7So-rOW00P3EJnlIWHuFogJXP2HRo3GtPdF9w2ZKjL4c5xeQU7XumD_O",
        currency: "CHF",
    };

    const amountNumber = typeof amount === 'number' ? amount : parseFloat(amount);

    return (
        <Dialog header="Checkout" visible={visible} style={{ width: '90vw' }} onHide={onHide} modal>
            <div style={{ marginBottom: '1rem' }}>
                <h3>Name: {subName}</h3>
                <h4>Preis: {amount} CHF</h4>
                <h4>Fee (3.7%): {(amountNumber * 0.037).toFixed(2)} CHF</h4>
                <h3>Total: {(amountNumber * 1.037).toFixed(2)} CHF</h3>
                <h3>Total: {(amountNumber).toFixed(2)} CHF</h3>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <Messages className='subscription-info-card' ref={msgs} />
            </div>

            <PayPalScriptProvider options={scriptOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                    }}
                    fundingSource={FUNDING.CARD}
                    createOrder={(data, actions) => {
                        if (actions?.order) {
                            msgs.current?.clear();
                            msgs.current?.show({
                                severity: 'warn',
                                sticky: true,
                                closable: false,
                                detail: 'Wenn Sie auf "Jetzt kaufen" klicken, aktualisieren Sie die Seite bitte nicht, bis eine Meldung angezeigt wird!',
                            });
                            const orderObj: { intent: "CAPTURE"; purchase_units: { amount: { currency_code: string; value: string; }; description: string; custom_id?: string; }[] } = {
                                intent: "CAPTURE",
                                purchase_units: [{
                                    amount: {
                                        currency_code: currency,
                                        value: (amountNumber * 1.035).toFixed(2)
                                        // value: amountNumber.toFixed(2) // WITHOUT FEE IS FOR TESTING PURPOSES
                                    },
                                    description: subName,
                                    custom_id: userId ?? undefined
                                }],
                            }
                            return actions.order.create(orderObj);
                        } else {
                            throw new Error("Failed to create order: actions.order is undefined.");
                        }
                    }}
                    onApprove={(data, actions) => {
                        if (actions?.order) {
                            return actions.order.capture().then(details => {
                                onSuccess(details);
                                onHide();
                            });
                        } else {
                            throw new Error("Failed to capture order: actions.order is undefined.");
                        }
                    }}
                    onError={(err) => {
                        onError(err);
                    }}
                />
            </PayPalScriptProvider>
        </Dialog>
    );
}

export default PayPalModal;
